var PROD = true;
(function () {
'use strict';

var Config = {
  DEBUG: !PROD,
  MAPBOX_USER: 'dobermangroup',
  MAPBOX_TOKEN: 'pk.eyJ1IjoiZG9iZXJtYW5ncm91cCIsImEiOiJjamVlM291ZGsyNW5mMzNzNmh5YWgzOWF1In0._o2YMdXOzR7RbitWfi4DzA',
  MAPBOX_STYLE: 'cjee88q53d0ds2snrv5e1u6wm'
};

function ifDebug(func) {
  return function () {
    if (Config.DEBUG) {
      func.apply(undefined, arguments);
    }
  };
}

var Logger = {
  debug: ifDebug(function () {
    var _console;

    (_console = console).debug.apply(_console, arguments);
  }),

  log: ifDebug(function () {
    var _console2;

    (_console2 = console).log.apply(_console2, arguments);
  }),

  info: ifDebug(function () {
    var _console3;

    (_console3 = console).info.apply(_console3, arguments);
  })
};

var zeroPad = function zeroPad(string) {
  var int = parseInt(string);

  if (isNaN(int) || !isFinite(int)) {
    return string;
  } else {
    return int >= 10 ? string : '0' + int;
  }
};

var normalize = function normalize(value, min, max) {
  return (value - min) / (max - min);
};

var debounce = function debounce(func, wait, immediate) {
  var timeout = void 0;

  return function () {
    var context = this;
    var args = arguments;

    var later = function later() {
      timeout = null;

      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

var throttle = function throttle(func, threshhold, scope) {
  threshhold = threshhold || 250;

  var deferTimer = void 0;
  var last = void 0;

  return function () {
    var context = scope || this;
    var now = +new Date();
    var args = arguments;

    if (last && now < last + threshhold) {
      clearTimeout(deferTimer);

      deferTimer = setTimeout(function () {
        last = now;
        func.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      func.apply(context, args);
    }
  };
};

var shuffle = function shuffle(array) {
  var currentIndex = array.length,
      temporaryValue = void 0,
      randomIndex = void 0;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

var isMobileDevice = function isMobileDevice() {
  var IS_ANDROID = /Android/;
  var IS_IOS = /iPad|iPhone|iPod/;

  return IS_ANDROID.test(navigator.userAgent) && !window.MSStream || IS_IOS.test(navigator.userAgent) && !window.MSStream;
};

var isInternetExplorer = function isInternetExplorer() {
  var IS_IE = /MSIE|Trident|Edge/;

  return IS_IE.test(navigator.userAgent);
};

var ScrollPathWaypoint = function ScrollPathWaypoint() {
  Logger.debug('ScrollPathWaypoint.constructor');

  this.path = undefined;
  this.containerEl = undefined;
  this.waypointEls = undefined;
  this.waypointElPositions = undefined;
  this.scrollDistanceSubscribers = [];

  window.addEventListener('resize', debounce(this.rebuildScrollPathWaypoint.bind(this), 500), { passive: true });

  this.initializeProperties();
  this.initializeScrollPathWaypoint();
};

ScrollPathWaypoint.prototype.rebuildScrollPathWaypoint = function () {
  Logger.debug('ScrollPathWaypoint.rebuildScrollPathWaypoint');

  window.location.reload();
};

ScrollPathWaypoint.prototype.initializeProperties = function () {
  Logger.debug('ScrollPathWaypoint.initializeProperties');

  this.path = $.fn.scrollPath('getPath');
  this.containerEl = document.querySelector('.js-scroll-container');
  this.outerContainerEl = document.querySelector('.js-scroll-outer-container');
  this.waypointEls = Array.prototype.slice.call(document.querySelectorAll('.js-scroll-waypoint'));
  this.waypointElOffsets = this.waypointEls.map(function (waypointEl) {
    return $(waypointEl).offset();
  });

  this.outerContainerEl.style.display = 'none';
};

ScrollPathWaypoint.prototype.initializeScrollPathWaypoint = function () {
  Logger.debug('ScrollPathWaypoint.initializeScrollPathWaypoint');

  this.drawScrollPathWaypoint();

  $(this.containerEl).scrollPath({
    drawPath: false,
    wrapAround: false,
    scrollBar: false
  });

  $.fn.scrollPath('subscribeToScroll', this.onScroll.bind(this));

  this.outerContainerEl.style.top = '-50vh';
  this.outerContainerEl.style.left = '-50vw';
  this.outerContainerEl.style.display = 'block';
};

ScrollPathWaypoint.prototype.drawScrollPathWaypoint = function () {
  var _this = this;

  Logger.debug('ScrollPathWaypoint.drawScrollPathWaypoint');

  this.waypointEls.forEach(function (waypointEl, index) {
    if (index > 0) {
      var scrollMethod = waypointEl.getAttribute('data-scroll-method');

      switch (scrollMethod) {
        case 'line':
          _this.drawLine(waypointEl, index);
          break;
        default:
          _this.drawLine(waypointEl, index);
          break;
      }
    }
  });
};

ScrollPathWaypoint.prototype.drawLine = function (waypointEl, waypointIndex) {
  var _this2 = this;

  var fromOffset = this.waypointElOffsets[waypointIndex - 1];
  var toOffset = this.waypointElOffsets[waypointIndex];
  var coordinateOrder = waypointEl.getAttribute('data-scroll-coordinate-order');

  if (coordinateOrder) {
    var options = { name: '' };

    coordinateOrder.split(',').forEach(function (coordinate, index) {
      options.name = 'waypoint-' + _this2.waypointEls.indexOf(waypointEl);

      if (index === 0) {
        if (coordinate === 'x') {
          _this2.path.lineTo(toOffset.left, fromOffset.top, options);
        } else if (coordinate === 'y') {
          _this2.path.lineTo(fromOffset.left, toOffset.top, options);
        }
      } else {
        _this2.path.lineTo(toOffset.left, toOffset.top, options);
      }
    });
  }
};

ScrollPathWaypoint.prototype.onScroll = function (scrollX, scrollY, event) {
  this.scrollDistanceSubscribers.forEach(function (subscriber) {
    return subscriber(scrollX, scrollY, event);
  });
};

ScrollPathWaypoint.prototype.subscribeToScrollDistance = function (subscriberCallback) {
  if (typeof subscriberCallback === 'function' && this.scrollDistanceSubscribers.indexOf(subscriberCallback) < 0) {
    this.scrollDistanceSubscribers.push(subscriberCallback);
  }
};

var Sections = function Sections(afterInitCallback) {
  Logger.debug('Sections.constructor');

  this.afterInitCallback = afterInitCallback;

  this.sectionEl = document.querySelector('.js-section');

  this.positionSections();
};

Sections.prototype.positionSections = function () {
  Logger.debug('Sections.positionSections');

  if (typeof this.afterInitCallback === 'function') {
    this.afterInitCallback();
  } else {
    throw new Error('Sections.positionSections: this.afterInitCallback is not a function');
  }
};

var ScratchCanvas = function ScratchCanvas(containerEl) {
  Logger.debug('ScratchCanvas.constructor');

  this.containerEl = containerEl;
  this.canvas = document.createElement('canvas');
  this.ctx = this.canvas.getContext('2d');
  this.backgroundCanvas = this.containerEl.querySelector('.js-canvas-background');
  this.backgroundCtx = this.backgroundCanvas.getContext('2d');

  this.currentImageIndex = 0;
  this.observer = undefined;
  this.isLoadingImage = false;

  this.canvasWidth = undefined;
  this.canvasHeight = undefined;
  this.canvasFillSize = undefined;

  this.images = [];

  for (var i = 1; i <= 15; ++i) {
    this.images.push('img/faces/faces_' + zeroPad(i) + '.jpg');
  }

  this.containerEl.appendChild(this.canvas);

  this.addEventListeners();
  this.setupCanvasSize();
  this.addImage();
};

ScratchCanvas.prototype.addEventListeners = function () {
  this.containerEl.addEventListener('mousemove', throttle(this.onMouseMove.bind(this), 75), { passive: true });

  window.addEventListener('resize', this.onWindowResize.bind(this));
};

ScratchCanvas.prototype.setupCanvasSize = function () {
  this.canvasWidth = parseInt(window.innerWidth / 100 * 75, 10);
  this.canvasHeight = parseInt(this.canvasWidth / (16 / 9), 10);
  this.canvasFillSize = parseInt(this.canvasWidth / 5, 10);

  this.canvas.width = this.canvasWidth;
  this.canvas.height = this.canvasHeight;
  this.backgroundCanvas.width = this.canvasWidth;
  this.backgroundCanvas.height = this.canvasHeight;
};

ScratchCanvas.prototype.addImage = function () {
  var _this = this;

  Logger.debug('ScratchCanvas.addImage');

  if (this.isLoadingImage) {
    return;
  }

  var image = new Image();

  if (this.currentImageIndex > this.images.length - 1) {
    this.currentImageIndex = 0;
  }

  image.src = this.images[this.currentImageIndex];
  image.onload = function () {
    return _this.onImageLoad(image);
  };

  this.isLoadingImage = true;
  this.currentImageIndex++;
};

ScratchCanvas.prototype.onImageLoad = function (image) {
  var _this2 = this;

  Logger.debug('ScratchCanvas.onImageLoad');

  setTimeout(function () {
    return _this2.addImage();
  }, 1500);

  var tempCanvas = document.createElement('canvas');
  var tempCtx = tempCanvas.getContext('2d');

  tempCanvas.width = this.canvasWidth;
  tempCanvas.height = this.canvasHeight;

  var imageScaleRatio = this.canvasWidth / image.width;

  this.backgroundCtx.drawImage(this.canvas, 0, 0, this.canvasWidth, this.canvasHeight);

  tempCtx.drawImage(image, 0, 0, image.width, image.height, 0, 0, tempCanvas.width, image.height * (imageScaleRatio * 0.75));

  this.ctx.fillStyle = this.ctx.createPattern(tempCanvas, 'no-repeat');

  this.isLoadingImage = false;
};

ScratchCanvas.prototype.onMouseMove = function (event) {
  var clientRect = this.containerEl.getBoundingClientRect();
  var x = event.clientX - clientRect.left;
  var y = event.clientY - clientRect.top;

  this.ctx.beginPath();
  this.ctx.moveTo(x + this.canvasFillSize, y);
  this.ctx.rect(x - this.canvasFillSize / 2, y - this.canvasFillSize / 2, this.canvasFillSize, this.canvasFillSize);
  this.ctx.fill();
};

ScratchCanvas.prototype.onWindowResize = function (event) {
  this.setupCanvasSize();
  this.addImage();
};

var HermanGlobe = function HermanGlobe(el) {
  Logger.debug('HermanGlobe.constructor');

  this.el = el;
  this.observer = undefined;
  this.weInstance = undefined;

  this.addEventListeners();
  this.setupObserver();
  this.buildHermanGlobe();
};

HermanGlobe.prototype.addEventListeners = function () {
  window.addEventListener('resize', this.onWindowResize.bind(this));
};

HermanGlobe.prototype.setupObserver = function () {
  this.observer = new IntersectionObserver(this.onObservation.bind(this), { rootMargin: '0%' });

  this.observer.observe(this.el);
};

HermanGlobe.prototype.onObservation = function (entries, observer) {
  var _this = this;

  entries.forEach(function (entry) {
    if (entry.intersectionRatio > 0) {
      _this.startAnimation();

      observer.unobserve(entry.target);
    }
  });
};

HermanGlobe.prototype.buildHermanGlobe = function () {
  var tileLayerUrl = 'https://api.mapbox.com/styles/v1/' + Config.MAPBOX_USER + '/' + Config.MAPBOX_STYLE + '/tiles/256/{z}/{x}/{y}@2x?access_token=' + Config.MAPBOX_TOKEN;

  this.weInstance = new WE.map(this.el, {
    center: [-120.505, -0.09],
    zoom: 2,
    scrollWheelZoom: true,
    unconstrainedRotation: false,
    zooming: false
  });

  WE.tileLayer(tileLayerUrl).addTo(this.weInstance);

  var marker1 = WE.marker([51.507351, -0.09], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker2 = WE.marker([-6.174465, 106.822745], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker3 = WE.marker([-22.906847, -43.172896], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker4 = WE.marker([59.3255, 18.0711], 'img/pin-office.png', 18, 18).addTo(this.weInstance);
  var marker5 = WE.marker([40.712784, -74.005941], 'img/pin-office.png', 18, 18).addTo(this.weInstance);
  var marker6 = WE.marker([59.934280, 30.335099], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker7 = WE.marker([53.349805, -6.260310], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker8 = WE.marker([37.566535, 126.977969], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker9 = WE.marker([31.200092, 29.918739], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker10 = WE.marker([42.662914, 21.165503], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker11 = WE.marker([5.603717, -0.186964], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker12 = WE.marker([31.768319, 35.213710], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker13 = WE.marker([25.032969, 121.565418], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker14 = WE.marker([41.385064, 2.173403], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker15 = WE.marker([48.856614, 2.352222], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker16 = WE.marker([52.370216, 4.895168], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker17 = WE.marker([55.676097, 12.568337], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker18 = WE.marker([59.913869, 10.752245], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker19 = WE.marker([47.606209, -122.332071], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker20 = WE.marker([37.774929, -122.419416], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker21 = WE.marker([28.538335, -81.379236], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker22 = WE.marker([60.169856, 24.938379], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker23 = WE.marker([39.904211, 116.407395], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker24 = WE.marker([39.084158, 117.200983], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker25 = WE.marker([48.135125, 11.581981], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker26 = WE.marker([34.052234, -118.243685], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker27 = WE.marker([30.267153, -97.743061], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker28 = WE.marker([22.318567, 114.179606], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker29 = WE.marker([50.064650, 19.944980], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker30 = WE.marker([52.520007, 13.404954], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker31 = WE.marker([44.801485, 10.327904], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker32 = WE.marker([48.208174, 16.373819], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker33 = WE.marker([25.7617, -80.1918], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker34 = WE.marker([43.6532, -79.3832], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker35 = WE.marker([53.551085, 9.993682], 'img/pin.png', 8, 8).addTo(this.weInstance);
  var marker36 = WE.marker([35.898908, 14.514553], 'img/pin.png', 8, 8).addTo(this.weInstance);

  this.weInstance.setView([40.505, 14], 2.5);
};

HermanGlobe.prototype.startAnimation = function () {
  // Start a simple rotation animation
  var self = this;
  var before = null;

  requestAnimationFrame(function animate(now) {
    var c = self.weInstance.getPosition();
    var elapsed = before ? now - before : 0;

    before = now;
    self.weInstance.setCenter([c[0], c[1] + 0.05 * (elapsed / 60)]);

    requestAnimationFrame(animate);
  });
};

HermanGlobe.prototype.onWindowResize = function (event) {
  this.weInstance.handleResize();
};

var PATH_LENGTH_SPEED_FACTOR = 6;

var preparePaths = function preparePaths(el) {
  var pathEls = Array.prototype.slice.call(el.querySelectorAll('path'));

  pathEls.forEach(function (pathEl) {
    var pathLength = pathEl.getTotalLength();

    pathEl.setAttribute('stroke-dasharray', pathLength);
    pathEl.setAttribute('stroke-dashoffset', pathEl.getAttribute('data-negative-dashoffset') ? -pathLength : pathLength);
  });
};

var animatePaths = function animatePaths(el) {
  var pathEls = Array.prototype.slice.call(el.querySelectorAll('path'));
  var animationDelay = 0;

  pathEls.forEach(function (pathEl) {
    var speedFactor = parseInt(pathEl.getAttribute('data-reveal-speed') || PATH_LENGTH_SPEED_FACTOR);
    var animationDuration = pathEl.hasAttribute('data-reveal-duration') ? parseInt(pathEl.getAttribute('data-reveal-duration')) : parseInt(pathEl.getTotalLength()) * speedFactor;
    var animationEase = pathEl.getAttribute('data-reveal-ease') || 'ease-in';

    pathEl.setAttribute('style', 'animation: reveal-path-animation ' + animationDuration + 'ms ' + animationEase + ' ' + animationDelay + 'ms forwards');

    animationDelay += Math.ceil(animationDuration);
  });
};

var Tickboxes = function Tickboxes(el) {
  Logger.debug('Tickboxes.constructor');

  this.el = el;
  this.revealObserver = undefined;
  this.animationDelay = 400;

  this.setupObservers();
};

Tickboxes.prototype.setupScribbles = function () {
  var scribbleEls = Array.prototype.slice.call(document.querySelector('.js-tickboxes-scribbles').children);
  var tickboxEls = Array.prototype.slice.call(this.tickboxEls);

  tickboxEls.pop();

  tickboxEls.forEach(function (tickboxEl, index) {
    var randomScribbleEl = scribbleEls[Math.floor(Math.random() * scribbleEls.length)];

    tickboxEl.appendChild(randomScribbleEl.cloneNode(true));
  });
};

Tickboxes.prototype.setupObservers = function () {
  var rootMargin = this.el.getAttribute('data-rootmargin') || '-40%';

  this.revealObserver = new IntersectionObserver(this.onRevealObservation.bind(this), { rootMargin: rootMargin });

  this.revealObserver.observe(this.el);
};

Tickboxes.prototype.prepareScribbles = function () {
  this.tickboxElsToAnimate.forEach(function (tickboxEl) {
    return preparePaths(tickboxEl);
  });
};

Tickboxes.prototype.onRevealObservation = function (entries, observer) {
  var _this = this;

  if (!this.tickboxElsToAnimate) {
    this.tickboxEls = Array.prototype.slice.call(this.el.children);
    this.tickboxElsToAnimate = this.tickboxEls.slice(Math.max(this.tickboxEls.length - 4, 1));

    this.setupScribbles();
    this.prepareScribbles();
  }

  entries.forEach(function (entry) {
    if (entry.intersectionRatio > 0) {
      _this.animateScribbles(entry.target);

      observer.unobserve(entry.target);
    }
  });
};

Tickboxes.prototype.animateScribbles = function () {
  var _this2 = this;

  this.tickboxElsToAnimate.forEach(function (tickboxEl, index) {
    setTimeout(function () {
      return animatePaths(tickboxEl);
    }, _this2.animationDelay * index);
  });
};

var RevealArrow = function RevealArrow(el) {
  Logger.debug('RevealArrow.constructor');

  this.el = el;
  this.revealObserver = undefined;

  this.setupElement();

  if (this.el.classList.contains('reveal-arrow--revealed')) {
    this.el.style.opacity = 1;
    animatePaths(this.el);
  } else {
    this.setupObservers();
  }
};

RevealArrow.prototype.setupElement = function () {
  preparePaths(this.el);
};

RevealArrow.prototype.setupObservers = function () {
  var rootMargin = this.el.getAttribute('data-rootmargin') ? this.el.getAttribute('data-rootmargin') : '0%';
  this.revealObserver = new IntersectionObserver(this.onRevealObservation.bind(this), { rootMargin: rootMargin });

  this.revealObserver.observe(this.el);
};

RevealArrow.prototype.onRevealObservation = function (entries, observer) {
  var _this = this;

  entries.forEach(function (entry) {
    if (entry.intersectionRatio > 0) {
      animatePaths(_this.el);

      observer.unobserve(entry.target);
    }
  });
};

var RevealParagraph = function RevealParagraph(el) {
  Logger.debug('RevealParagraph.constructor');

  this.el = el;
  this.revealClass = 'reveal-paragraph--is-revealed';
  this.revealObserver = undefined;

  this.setupObservers();
};

RevealParagraph.prototype.setupObservers = function () {
  Logger.debug('RevealParagraph.setupObservers');

  var rootMargin = this.el.getAttribute('data-rootmargin') || '0% 0% 0% 0%';

  this.revealObserver = new IntersectionObserver(this.onRevealObservation.bind(this), { rootMargin: rootMargin });

  this.revealObserver.observe(this.el);
};

RevealParagraph.prototype.onRevealObservation = function (entries, observer) {
  var _this = this;

  Logger.debug('RevealParagraph.onRevealObservation');

  entries.forEach(function (entry) {
    if (entry.intersectionRatio > 0) {
      _this.el.classList.add(_this.revealClass);

      observer.unobserve(entry.target);
    }
  });
};

var HoverImage = function HoverImage(el, imgSrc) {
  //Logger.debug('HoverImage.constructor');

  this.el = el;
  this.imgSrc = imgSrc;
  this.imgEl = document.querySelector('.js-hover-image-img');

  this.addEventListeners();
};

HoverImage.prototype.addEventListeners = function () {
  //Logger.debug('HoverImage.addEventListeners');

  this.el.addEventListener('mousemove', this.onMouseMove.bind(this));
  this.el.addEventListener('mouseover', this.onMouseEnter.bind(this));
  this.el.addEventListener('mouseout', this.onMouseLeave.bind(this));
};

HoverImage.prototype.onMouseMove = function (event) {
  var clientRect = this.el.getBoundingClientRect();
  var imgVertical = event.clientY;
  var imgHorizontal = event.clientX;

  this.imgEl.style.top = imgVertical + 'px';
  this.imgEl.style.left = imgHorizontal + 'px';
};

HoverImage.prototype.onMouseEnter = function (event) {
  var _this = this;

  this.imgEl.src = this.imgSrc;

  setTimeout(function () {
    _this.imgEl.classList.add('hover-image__img--is-visible');
  }, 8);

  document.body.classList.add('body--hover-image');
};

HoverImage.prototype.onMouseLeave = function (event) {
  this.imgEl.classList.remove('hover-image__img--is-visible');

  document.body.classList.remove('body--hover-image');
};

var FirstSection = function FirstSection(scrollPathInstance) {
  var _this = this;

  Logger.debug('FirstSection.constructor');

  this.el = document.querySelector('.js-first-section');
  this.elWidth = this.el.offsetWidth;
  this.elHeight = this.el.offsetHeight;

  this.yearTickboxesEl = this.el.querySelector('.js-tickboxes');

  this.smileyEl = this.el.querySelector('.js-smiley');
  this.smileyTimeline = undefined;
  this.smileyStartDistancePercentage = 0.1;
  this.smileyEndDistancePercentage = 1.9;

  if (scrollPathInstance) {
    scrollPathInstance.subscribeToScrollDistance(this.onScroll.bind(this));
  }

  window.addEventListener('resize', function () {
    _this.elWidth = _this.el.offsetWidth;
    _this.elHeight = _this.el.offsetHeight;

    _this.createSmileyTimeline();
  });

  this.createYearTickboxes();
  this.createSmileyTimeline();
  this.createSmileyBlinkAnimation();
};

FirstSection.prototype.createYearTickboxes = function () {
  var foundingYear = 1998;
  var currentYear = new Date().getFullYear();
  var yearTickboxesHTML = '';

  for (var i = foundingYear; i <= currentYear; ++i) {
    if (i < currentYear) {
      yearTickboxesHTML += '<li>' + i + '</li>';
    } else {
      yearTickboxesHTML += '\n        <li>\n          <svg width="53" height="27" viewBox="0 0 53 27" xmlns="http://www.w3.org/2000/svg"><path d="M36.31 7.215c-6.804 0-13.623-.325-20.413 0-24.988 1.193 7.91 28.053 28.654 15.41 10.648-6.492 10.84-21.41-5.718-21.598C17.877.789 17.793 2.087 0 7.079" stroke="#004EFF" fill="none" fill-rule="evenodd" stroke-linecap="round"/></svg>\n          ' + i + '\n        </li>';
    }
  }

  this.yearTickboxesEl.innerHTML = yearTickboxesHTML;
};

FirstSection.prototype.createSmileyTimeline = function () {
  if (this.smileyTimeline) {
    this.smileyTimeline.kill();
    this.smileyTimeline = undefined;
  }

  var tl = new TimelineMax();
  var el = this.smileyEl;
  var elSize = window.innerWidth / 100 * 4.25;

  el.style.top = '-' + elSize + 'px';
  el.style.height = elSize + 'px';
  el.style.width = elSize + 'px';

  var elHeight = this.smileyEl.getBBox().height;

  tl.from(el, 0, { y: -elHeight, rotation: 0 }).to(el, 1, { y: window.innerHeight + elHeight + this.elHeight, rotation: 1080 }).time(0).pause();

  this.smileyTimeline = tl;
};

FirstSection.prototype.updateSmileyTimeline = function (toTime) {
  TweenMax.to(this.smileyTimeline, 0.25, { time: toTime, ease: Power4.easeOut });
};

FirstSection.prototype.onScroll = function (scrollX) {
  var scrollXRatio = scrollX / (this.elWidth + this.elHeight);

  if (scrollXRatio > 0.045) {
    this.showDimParagraph();
  }

  if (scrollXRatio < this.smileyStartDistancePercentage) {
    this.updateSmileyTimeline(0);
  } else if (scrollXRatio > this.smileyEndDistancePercentage) {
    this.updateSmileyTimeline(1);
  } else {
    var toTimeRatio = normalize(scrollXRatio, this.smileyStartDistancePercentage, this.smileyEndDistancePercentage);

    this.updateSmileyTimeline(toTimeRatio);
  }
};

FirstSection.prototype.createSmileyBlinkAnimation = function () {
  var openEyeEl = this.smileyEl.querySelector('.js-smiley-open-eye');
  var closedEyeEl = this.smileyEl.querySelector('.js-smiley-closed-eye');

  var isOpen = true;

  setInterval(function () {
    if (isOpen) {
      openEyeEl.style.opacity = 0;
      closedEyeEl.style.opacity = 1;
    } else {
      openEyeEl.style.opacity = 1;
      closedEyeEl.style.opacity = 0;
    }

    isOpen = !isOpen;
  }, 750);
};

FirstSection.prototype.showDimParagraph = function () {
  document.querySelector('.first-section__dim-paragraph').classList.add('first-section__dim-paragraph--is-visible');
};

var FourthSection = function FourthSection(scrollPathInstance) {
  var _this = this;

  Logger.debug('FourthSection.constructor');

  this.el = document.querySelector('.js-fourth-section');
  this.elWidth = this.el.offsetWidth;
  this.elOffsetLeft = this.el.offsetLeft;

  this.penroseEl = this.el.querySelector('.js-penrose');
  this.penroseTimeline = undefined;
  this.penroseStartDistancePercentage = 0.7;
  this.penroseEndDistancePercentage = 1;

  if (scrollPathInstance) {
    scrollPathInstance.subscribeToScrollDistance(this.onScroll.bind(this));
  }

  window.addEventListener('resize', function () {
    _this.elWidth = _this.el.offsetWidth;
    _this.elOffsetLeft = _this.el.offsetLeft;

    _this.createPenroseTimeline();
  });

  this.createPenroseTimeline();
};

FourthSection.prototype.createPenroseTimeline = function () {
  if (this.penroseTimeline) {
    this.penroseTimeline.kill();
    this.penroseTimeline = undefined;
  }

  var tl = new TimelineMax();
  var el = this.penroseEl;
  var xTranslation = window.innerWidth / 100 * 30;

  TweenMax.set(el, { alpha: 0 });

  tl.from(el, 0, { alpha: 0, x: 0, rotation: 0 }).to(el, 1, { alpha: 1, x: xTranslation + 'px', rotation: 0 }).time(0).pause();

  this.penroseTimeline = tl;
};

FourthSection.prototype.updatePenroseTimeline = function (toTime) {
  TweenMax.to(this.penroseTimeline, 0.5, { time: toTime, ease: Power4.easeOut });
};

FourthSection.prototype.onScroll = function (scrollX) {
  var scrollXRatio = scrollX / (this.elWidth + this.elOffsetLeft);

  if (scrollXRatio < this.penroseStartDistancePercentage) {
    this.updatePenroseTimeline(0);
  } else if (scrollXRatio > this.penroseEndDistancePercentage) {
    this.updatePenroseTimeline(1);
  } else {
    var toTimeRatio = normalize(scrollXRatio, this.penroseStartDistancePercentage, this.penroseEndDistancePercentage);

    this.updatePenroseTimeline(toTimeRatio);
  }
};

var FifthSection = function FifthSection(scrollPathInstance) {
  Logger.debug('FifthSection.constructor');

  this.el = document.querySelector('.js-fifth-section');
  this.endingEl = this.el.querySelector('.js-fifth-section-ending');
  this.hasShownEnding = false;

  if (scrollPathInstance) {
    scrollPathInstance.subscribeToScrollDistance(this.onScroll.bind(this));
  }
};

FifthSection.prototype.onScroll = function (x, y, event) {
  if (event && event === 'end' && !this.hasShownEnding) {
    this.hasShownEnding = true;

    this.showEnding();
  }
};

FifthSection.prototype.showEnding = function () {
  this.endingEl.classList.add('fifth-section__ending--is-visible');
};

//import { InitialSection } from './initial-section';
var Desktop = function Desktop() {
  document.documentElement.classList.add('html--is-desktop');

  // Setup Tickboxes
  var tickboxesEls = Array.prototype.slice.call(document.querySelectorAll('.js-tickboxes'));
  var tickboxesInstances = [];

  tickboxesEls.forEach(function (tickboxesEl) {
    tickboxesInstances.push(new Tickboxes(tickboxesEl));
  });

  // Setup RevealParagraphs
  var revealParagraphEls = Array.prototype.slice.call(document.querySelectorAll('.js-reveal-paragraph'));
  var revealParagraphInstances = [];

  revealParagraphEls.forEach(function (revealParagraphEl) {
    revealParagraphInstances.push(new RevealParagraph(revealParagraphEl));
  });

  // Setup RevealArrows
  var revealArrowEls = Array.prototype.slice.call(document.querySelectorAll('.js-reveal-arrow'));
  var revealArrowInstances = [];

  revealArrowEls.forEach(function (revealArrowEl) {
    revealArrowInstances.push(new RevealArrow(revealArrowEl));
  });

  // Setup ScratchCanvas
  var scratchCanvasEl = document.querySelector('.js-canvas-container');
  var scratchCanvasInstance = new ScratchCanvas(scratchCanvasEl);

  // Setup HermanGlobe
  var hermanGlobeEl = document.querySelector('.js-herman-globe');
  var hermanGlobeInstance = new HermanGlobe(hermanGlobeEl);

  // Setup HoverImage
  var hoverImageEls = Array.prototype.slice.call(document.querySelectorAll('.js-hover-image'));
  var hoverImageInstances = [];
  var imgSrcArray = [];
  var imgSrcIndex = 0;

  for (var i = 1; i <= 87; ++i) {
    imgSrcArray.push(i);
  }

  imgSrcArray = shuffle(imgSrcArray);

  hoverImageEls.forEach(function (hoverImageEl) {
    var imgSrc = 'img/slide-placeholder-1.jpg';

    if (hoverImageEl.getAttribute('data-img-src')) {
      imgSrc = hoverImageEl.getAttribute('data-img-src');
    } else {
      if (imgSrcIndex >= imgSrcArray.length - 1) {
        imgSrcIndex = 0;
      }

      imgSrc = 'img/culture/culture_' + zeroPad(imgSrcArray[imgSrcIndex]) + '.jpg';

      // Add prefetch <link>
      var prefetchLink = document.createElement('link');

      prefetchLink.rel = 'prefetch';
      prefetchLink.href = imgSrc;

      document.head.appendChild(prefetchLink);

      imgSrcIndex++;
    }

    hoverImageInstances.push(new HoverImage(hoverImageEl, imgSrc));
  });

  // Preload faces images
  for (var j = 1; j <= 15; ++j) {
    var prefetchLink = document.createElement('link');

    prefetchLink.rel = 'prefetch';
    prefetchLink.href = 'img/faces/faces_' + zeroPad(j) + '.jpg';

    document.head.appendChild(prefetchLink);
  }

  // Setup ScrollPathWaypoint
  var scrollPathWaypoint = new ScrollPathWaypoint();
  //$.fn.scrollPath('scrollTo', 'waypoint-2');

  // Setup Sections, InitialSection and FirstSection
  var initialSectionInstance = void 0,
      firstSectionInstance = void 0,
      fourthSectionInstance = void 0,
      fifthSectionInstance = void 0;

  var sectionsInstance = new Sections(function () {
    //initialSectionInstance = new InitialSection(scrollPathWaypoint);
    firstSectionInstance = new FirstSection(scrollPathWaypoint);
    fourthSectionInstance = new FourthSection(scrollPathWaypoint);
    fifthSectionInstance = new FifthSection(scrollPathWaypoint);
  });
};

var Paragraph = function Paragraph(el) {
  this.el = el;
  this.observer = undefined;
  this.prevRatio = 0.0;

  this.observe();
};

Paragraph.prototype.observe = function () {
  var options = {
    root: null,
    rootMargin: '-10%',
    threshold: this.threshold()
  };

  this.observer = new IntersectionObserver(this.handleIntersect, options);
  this.observer.observe(this.el);
};

Paragraph.prototype.threshold = function () {
  var thresholds = [];
  var numSteps = 20.0;

  for (var i = 1.0; i <= numSteps; i++) {
    var ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);

  return thresholds;
};

Paragraph.prototype.handleIntersect = function (entries, observer) {
  entries.forEach(function (entry) {
    entry.target.style.color = '#fff';
  });
};

var Mobile = function Mobile() {
  document.documentElement.classList.add('html--is-mobile');
};

var DESKTOP = document.querySelector('.js-desktop');
var MOBILE = document.querySelector('.js-mobile');

if (isMobileDevice() || isInternetExplorer()) {
  DESKTOP.parentNode.removeChild(DESKTOP);

  Mobile();
} else {
  MOBILE.parentNode.removeChild(MOBILE);

  window.onload = Desktop;
}

}());
